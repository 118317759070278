import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useStore } from 'store';
import { client } from 'utilities/db';
import { getUser } from 'utilities/helpers/auth';
import { RenderIf } from 'utilities/helpers';
import { useFetchCategoriesAll, useFetchColors } from 'hooks/resourceHooks';
import { useFetchBranches } from 'hooks/resourceHooks';
import { AddMenuCatgory } from './add';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { Ikon } from 'assets/icons';
import { Loading } from 'components/loading';
import PlaceholderImage from 'assets/Images/placeholder.png';

export const MenuCategory = () => {
  const { brand, branch } = useStore((state) => state);
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(
        isEdit ? `/category/update/` : `/category/add`,
        userData
      );
    }
  );
  const { data: colorsList } = useFetchColors();
  const { data: branchData, isLoading: branchLoading } = useFetchBranches(
    brand?.id
  );
  const {
    refetch: refetchMenu,
    isLoading: menuIsLoading,
    error: menuError,
    data: menuData = [],
    isSuccess: menuIsSuccess
  } = useFetchCategoriesAll();
  const navigate = useNavigate();
  const user = getUser();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [additionType, setAdditionType] = useState('upload');
  const [isEdit, setIsEdit] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const allMenus = menuData?.filter((item) =>
    item.category_name?.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
      return;
    }
  }, [user, navigate]);

  const onEditClick = (val) => {
    // setIsEdit(true);
    // setselectedCategory(val);
    // setAdditionType('add');
    // setIsOpenModal(true);
    // setIsActivate(val?.is_active);
  };

  const addMenu = (val) => {
    let typesArr = [{ name: '' }];
    typesArr =
      val?.category_types?.length &&
      val?.category_types?.map((cat) => {
        return {
          name: cat?.label
        };
      });
    mutate({
      image: uploadImage,
      name: val?.name,
      description: val?.description,
      category_types: typesArr,
      is_active: val?.is_active,
      color_id: val?.color_id,
      is_deleted: false,
      brand_id: brand?.id,
      branch_ids: [branch?.id],
      category_level: val?.branch_ids?.length > 0 ? 'BRANCH' : 'BRAND'
    });
  };

  const onClose = () => {
    setIsOpenModal(false);
    setIsEdit(false);
    setUploadImage('');
  };

  useMemo(() => {
    if (isSuccess) {
      refetchMenu();
      onClose();
      Toasts.success('Category has been added.');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(
      error?.response?.data?.message ||
        'Some thing went wrong please try again later'
    );
  }, [isError]);

  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Menu category</strong>
          </h1>
          <h5 className="mt_4">Add or import a menu category</h5>
        </div>
        <div style={{ opacity: 0.4 }}>
          <Button disabled label={'Download guidebook'} color="bg-titary" />
        </div>
      </div>
      <div className="mt_32"></div>
      <div className="add_new_grid">
        <AddMenuCards
          name="fileChecked"
          heading={`Add category using template`}
          description={`Please import your menu category by populating this template.`}
          onClick={() => {
            setAdditionType('upload');
            setIsOpenModal(true);
          }}
          disabled
        />
        <AddMenuCards
          name="fileScratch"
          heading={`Add category`}
          description={`Create your menu category from scratch.`}
          onClick={() => {
            setAdditionType('add');
            setIsOpenModal(true);
          }}
        />
      </div>
      <div className="mt_32"></div>
      <h4>
        <span className="ff_semi">Categories</span>
      </h4>
      <div className="mt_32"></div>
      <div className="box2 flex item_vcenter item_space_btn">
        <div className="w_356">
          <TextField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder={'Search'}
          />
        </div>
        <div></div>
      </div>
      <div className="mt_40">
        {menuIsLoading ? (
          <div className={`loading_full`}>
            <Loading />
          </div>
        ) : (
          <div className="cat_grid">
            {allMenus?.map((item, i) => (
              <div key={i} className="cat_col">
                {colorsList?.map((color, index) =>
                  color?.id === item?.color_id ? (
                    <div
                      key={`${i}-${index}`}
                      onClick={() => onEditClick(item)}
                      className="cat_box card_item1"
                      style={{
                        borderLeft: `${color?.hex} 6px solid`
                      }}
                    >
                      <RenderIf isTrue={!item?.image}>
                        <picture className="cat_img">
                          <source srcSet={PlaceholderImage} />
                          <img src={PlaceholderImage} alt="" />
                        </picture>
                      </RenderIf>
                      <RenderIf isTrue={item?.image}>
                        <div>
                          <picture className="cat_img">
                            <source srcSet={item?.image} />
                            <img src={item?.image} alt="" />
                          </picture>
                        </div>
                      </RenderIf>

                      <div className="cat_head">
                        <div className="cat_name">{item?.category_name}</div>
                        {item?.CategoryTypes?.slice(1, 3)?.map(
                          (type, index) => (
                            <div key={index} className="badge">
                              {type?.category_type_name}
                            </div>
                          )
                        )}
                      </div>
                      <div className="cat_description">
                        {item?.category_description}
                      </div>
                      <RenderIf isTrue={branchData?.length}>
                        <div className="mt_12"></div>
                      </RenderIf>
                      <div className="flex item_space_btn">
                        <div>
                          {branchData?.length &&
                            branchData?.map((branch, ind) =>
                              branch?.id === item?.branch_id ? (
                                <div key={ind} className="attach_branch">
                                  <div className="attach_ikon">
                                    <Ikon name={`attachbox`} />
                                  </div>
                                  <div>{branch?.branch_name}</div>
                                </div>
                              ) : null
                            )}
                        </div>
                        <div>
                          <button
                            className="alink"
                            onClick={() =>
                              navigate(
                                `/menu-engineering/category/detail/${item?.id}`
                              )
                            }
                          >
                            View detail
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {isOpenModal && (
        <AddMenuCatgory
          branchData={branchData}
          branchLoading={branchLoading}
          isLoading={isLoading || uploadImageLoading}
          addMenu={addMenu}
          additionType={additionType}
          uploadImage={uploadImage}
          setUploadImageLoading={setUploadImageLoading}
          setUploadImage={setUploadImage}
          isOpenModal={isOpenModal}
          onClose={onClose}
          colorsList={colorsList}
        />
      )}
    </>
  );
};

const AddMenuCards = ({ name, heading, description, onClick, disabled }) => {
  return (
    <>
      <div
        style={{
          opacity: disabled ? 0.4 : 1,
          pointerEvents: disabled ? 'none' : 'all'
        }}
      >
        <button className="add_new_button" onClick={onClick}>
          <div>
            <div>
              <Ikon name={name} />
            </div>
            <h4>{heading}</h4>
            <p>{description}</p>
          </div>
        </button>
      </div>
    </>
  );
};
