import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useStore } from 'store';
import { client } from 'utilities/db';
import {
  useFetchCategories,
  useFetchMenusByBrand,
  useFetchBranches
} from 'hooks/resourceHooks';
import { getUser } from 'utilities/helpers/auth';
import { RenderIf } from 'utilities/helpers';
import { AddMenuItem } from './add';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { Ikon } from 'assets/icons';
import { Loading } from 'components/loading';
import PlaceholderImage from 'assets/Images/placeholder.png';

export const MenuItem = () => {
  const { brand, branch } = useStore((state) => state);
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(isEdit ? `/menu/update` : `/menuItem/add`, userData);
    }
  );
  const {
    refetch: refetchMenu,
    isLoading: menuIsLoading,
    error: menuError,
    data: menuData = [],
    isSuccess: menuIsSuccess
  } = useFetchMenusByBrand();
  const navigate = useNavigate();
  const user = getUser();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [additionType, setAdditionType] = useState('upload');
  const [isEdit, setIsEdit] = useState(false);
  const [searchField, setSearchField] = useState('');
  const { data: allCategories } = useFetchCategories();

  const { data: branchData, isLoading: branchLoading } = useFetchBranches(
    brand?.id
  );

  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
      return;
    }
  }, [user]);

  const onEditClick = (val) => {
    // setIsEdit(true)
    // setAdditionType('add')
    // setIsOpenModal(true)
    // setCategory(allCategories?.filter(item => item.id === val?.category_id)?.map(item => ({ label: item?.name, value: item?.id })))
  };

  const addMenu = (val) => {
    // if (category.length === 0) {
    //   alert('Menu Item should be linked with atleast one category');
    //   return;
    // }

    mutate({
      brand_id: brand?.id,
      image: uploadImage,
      menu_item_level: val?.branch_ids?.length > 0 ? 'BRANCH' : 'BRAND',
      menu_item_name: val?.menu_item_name,
      menu_item_description: val?.menu_item_description,
      base_price: Number(val?.base_price),
      taxable: val?.taxable === true ? '1' : '0',
      quantity_threshold: val?.quantity_threshold,
      quantity_options: {
        force_quantity: val?.force_quantity,
        lock_quantity: val?.lock_quantity,
        hide_quantity: val?.hide_quantity
      },
      tags:
        val?.tags?.length && val?.tags?.length > 0
          ? val?.tags?.map((tag) => tag.value)
          : [],
      menu_status: val?.menu_status,
      category: val?.category?.value,
      category_types: val?.category_types ? [val?.category_types?.value] : [],
      branch_ids: [branch?.id],
      available_days: {
        monday: val?.monday,
        tuesday: val?.tuesday,
        wednesday: val?.wednesday,
        thursday: val?.thursday,
        friday: val?.friday,
        saturday: val?.saturday,
        sunday: val?.sunday
      },
      available_time: {
        start_time: val?.start_time,
        end_time: val?.end_time
      },
      sale_channels: {
        pos: val?.pos,
        online_store: val?.online_store,
        others: val?.others
      }
    });
  };

  const onClose = () => {
    setIsOpenModal(false);
    setIsEdit(false);
  };

  useMemo(() => {
    if (isSuccess && data) {
      client
        .post(`/modifierGroup/addModifier`, {
          brand_id: brand?.id,
          branch_id: branch?.id,
          modifier_group_name: 'BASE',
          modifier_group_description: '',
          modifier_group_level: 'BRANCH',
          is_required: true,
          image: '',
          menu_item_id: data?.data?.menuItem?.[0]?.id,
          modifiers: [
            {
              modifier_name: 'BASE',
              modifier_description: '',
              modifier_level: 'BRANCH',
              additional_price: '0',
              calories: 0,
              image: ''
            }
          ],
          settings: {
            free_choice_quantity: '',
            free_choice: false,
            modifier_charges: ''
          }
        })
        .then((res) => {
          console.log('🚀 ~ file: index.jsx:23 ~ MenuItem ~ res:', res);
        })
        .catch((err) => {
          console.log('🚀 ~ file: index.jsx:23 ~ MenuItem ~ err:', err);
        });

      refetchMenu();
      onClose();
      Toasts.success('Menu item has been added.');
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message);
  }, [isError]);

  const filteredMenuItem = menuData?.filter((item) => {
    return item?.menu_item_name
      ?.toLowerCase()
      ?.includes(searchField?.toLowerCase());
  });

  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Menu Items</strong>
          </h1>
          <h5 className="mt_4">Add or import a menu item</h5>
        </div>
        <div style={{ opacity: 0.4 }}>
          <Button disabled label={'Download guidebook'} color="bg-titary" />
        </div>
      </div>
      <div className="mt_32"></div>
      <RenderIf isTrue={allCategories?.length > 0}>
        <div className="add_new_grid">
          <AddMenuCards
            name="fileChecked"
            heading={`Add menu item using template`}
            description={`Please import your menu item by populating this template.`}
            onClick={() => {
              setAdditionType('upload');
              setIsOpenModal(true);
            }}
            disabled
          />
          <AddMenuCards
            name="fileScratch"
            heading={`Add menu item`}
            description={`Create you ZEWST menu item from scratch.`}
            onClick={() => {
              setAdditionType('add');
              setIsOpenModal(true);
            }}
          />
        </div>
      </RenderIf>
      <RenderIf isTrue={allCategories?.length === 0}>
        <AddMenuCards
          name="fileChecked"
          heading={`No category added yet.`}
          description={`You need to add a category first to add a menu item.`}
          onClick={() => {
            navigate('/menu-engineering/categories');
          }}
        />
      </RenderIf>

      <div className="mt_32"></div>
      <h4>
        <span className="ff_semi">Menu items</span>
      </h4>
      <div className="mt_32"></div>
      <div className="box2 flex item_vcenter item_space_btn">
        <div className="w_356">
          <TextField
            onChange={(e) => setSearchField(e.target.value)}
            value={searchField}
            placeholder={'Search'}
          />
        </div>
        <div></div>
      </div>

      <div className="mt_40">
        {menuIsLoading ? (
          <div className={`loading_full`}>
            <Loading />
          </div>
        ) : (
          <div className="cat_grid">
            {filteredMenuItem?.map((item, i) => (
              <div key={i} className="cat_col">
                <div
                  onClick={() => onEditClick(item)}
                  className="cat_box cat_box2 card_item1"
                >
                  <RenderIf isTrue={!item?.image}>
                    <picture className="cat_img">
                      <source srcSet={PlaceholderImage} />
                      <img src={PlaceholderImage} alt="Menu item" />
                    </picture>
                  </RenderIf>
                  <RenderIf isTrue={item?.image}>
                    <div>
                      <picture className="cat_img">
                        <source srcSet={item?.image} />
                        <img src={item?.image} alt="Menu item" />
                      </picture>
                    </div>
                  </RenderIf>

                  <div className="cat_head">
                    <div className="cat_name">{item?.menu_item_name}</div>
                    <div className="badge">{item?.menu_item_level}</div>
                  </div>
                  <div className="cat_description">
                    {item?.menu_item_description}
                  </div>
                  <div className="mt_12"></div>
                  <div className="flex item_space_btn">
                    <h6>
                      <b>${item?.price}</b>
                    </h6>
                    <div>
                      <button
                        className="alink"
                        onClick={() =>
                          navigate(`/menu-engineering/item/detail/${item?.id}`)
                        }
                      >
                        View detail
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {isOpenModal && (
        <AddMenuItem
          allCategories={allCategories}
          isLoading={isLoading || uploadImageLoading}
          addMenu={addMenu}
          additionType={additionType}
          uploadImage={uploadImage}
          setUploadImageLoading={setUploadImageLoading}
          setUploadImage={setUploadImage}
          onClose={onClose}
          branchData={branchData}
          branchLoading={branchLoading}
          isOpenModal={isOpenModal}
        />
      )}
    </>
  );
};

const AddMenuCards = ({ name, heading, description, onClick, disabled }) => {
  return (
    <>
      <div
        style={{
          opacity: disabled ? 0.4 : 1,
          pointerEvents: disabled ? 'none' : 'all'
        }}
      >
        <button className="add_new_button" onClick={onClick}>
          <div>
            <div>
              <Ikon name={name} />
            </div>
            <h4>{heading}</h4>
            <p>{description}</p>
          </div>
        </button>
      </div>
    </>
  );
};
