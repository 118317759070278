import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { RenderIf } from 'utilities/helpers';
import { useStore } from 'store';
import { useFetchFinixPaymentHook } from 'hooks/finix-payments/useFetchFinixPayment';
import { PrimaryButton } from 'components/button';

export default function Payments() {
  const navigate = useNavigate();

  const { branch } = useStore((state) => state);

  const branchId = branch?.id;

  const { paymentData, paymentLoading } = useFetchFinixPaymentHook(
    'payment',
    `${branchId}`,
    'BRANCH'
  );

  const paymentAccount = paymentData?.result;
  const accountInfo = paymentData?.result?.responsePaymentService;

  if (paymentLoading) {
    return (
      <div className={styles.page}>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <RenderIf
        isTrue={
          !paymentAccount ||
          !accountInfo?.identityInfo ||
          !paymentAccount?.existingMerchant?.bank_account_info
        }
      >
        <div className={styles.infoContainer}>
          <p className={styles.infoText}>
            Looks like you haven't setup a Payment Account yet.
          </p>
        </div>
        <div className={styles.body}>
          <p>
            To receive online payments you need to setup a Payment Account.{' '}
            <a href={`/payments/create`}
              // onClick={() => {
              //   navigate(`/payments/create`);
              // }}
            >
              Click here to setup your Payment Account.
            </a>
          </p>
        </div>
      </RenderIf>

      <RenderIf
        isTrue={
          accountInfo?.identityInfo &&
          accountInfo?.merchantInfo &&
          accountInfo?.bankAcountInfo
        }
      >
        <div className={styles.cardList}>
          <div className={styles.cardBx}>
            <header className={styles.header}>
              <div className={styles.icon}>
                {accountInfo?.bankAcountInfo?.currency}
              </div>
              <div className={styles.div}>
                <div className={styles.content}>
                  <p className={styles.p}>{accountInfo?.createdAt}</p>
                  <h5 className={styles.h5}>
                    {accountInfo?.identityInfo?.entity?.business_name}
                  </h5>
                  <h6 className={styles.h6}>
                    {accountInfo?.identityInfo?.entity?.email}
                  </h6>
                </div>
                <div>
                  <div
                    className={styles.badge}
                    style={{
                      background:
                        accountInfo?.onboardingStatus === 'APPROVED'
                          ? 'var(--primary)'
                          : 'red'
                    }}
                  >
                    {accountInfo?.onboardingStatus?.toLowerCase()}
                  </div>

                  <RenderIf
                    isTrue={accountInfo?.onboardingStatus !== 'APPROVED'}
                  >
                    <PrimaryButton
                      onClick={() => {
                        navigate(`/payments/update`);
                      }}
                    >
                      Update Details
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={() => {
                        navigate(`/payments/documents`);
                      }}
                    >
                      Upload Documents
                    </PrimaryButton>
                  </RenderIf>
                </div>
              </div>
            </header>
            <div className={styles.cardBody}>
              <div>
                <p className={styles.p}>Phone:</p>
                <h6 className={styles.h6}>
                  {accountInfo?.identityInfo?.entity?.business_phone}
                </h6>
              </div>
              <div className={styles.textRight}>
                <p className={styles.p}>Max transaction:</p>
                <h6 className={styles.h6}>
                  {accountInfo?.identityInfo?.entity?.max_transaction_amount}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </RenderIf>
    </div>
  );
}
