import { RenderIf } from 'utilities/helpers';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from './index.module.css';

export const CustomSelectDropdown = (props) => {
  const {
    ref = null,
    label,
    id,
    name,
    options,
    placeholder,
    isMulti,
    onChange = () => {},
    onInputChange = () => {},
    value,
    inputValue = '',
    error,
    defaultMenuIsOpen,
    field = null,
    hookForm = false,
    isLoading = false,
    select2 = false,
    typeArray = false,
    required,
    multiValue = true
  } = props;
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'var(--InterRegular)',
      fontSize: select2 ? '11px' : 'calc(var(--inputFontSize) - 2px)',
      color: state.isSelected ? 'var(--primary)' : 'rgb(20, 23, 31)',
      padding: '10px var(--inputHPadding)',
      backgroundColor: state.isFocused ? 'var(--lite1)' : 'transparent',
      borderRadius: 'var(--inputRadius)'
    }),
    control: () => ({
      fontFamily: 'var(--InterRegular)',
      width: '100%',
      minWidth: select2 ? '75px' : 'initial',
      height: select2 ? '20px' : 'var(--inputHeight)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: select2 ? 0 : 'var(--inputBorder)',
      borderBottom: select2 ? 'var(--lite) 1px solid' : 'var(--inputBorder)',
      borderRadius: select2 ? 0 : 'var(--inputRadius)',
      backgroundColor: 'var(--inputBackground)',
      padding: select2 ? 0 : '0 var(--inputHPadding) 16px'
    }),
    valueContainer: () => ({
      position: 'relative',
      padding: 0,
      display: 'flex',
      flex: '1',
      flexWrap: 'wrap',
      alignItems: 'center'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: () => ({
      padding: '0',
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'center',
      flexShrink: 0
    }),
    dropdownIndicator: () => ({
      opacity: 0.24,
      lineHeight: 0
    }),
    multiValue: () => ({
      backgroundColor: 'var(--primary)',
      borderRadius: '2px',
      display: 'flex',
      margin: 2,
      minWidth: 0
    }),
    multiValueLabel: () => ({
      color: 'var(--white)',
      fontSize: select2 ? '11px' : '12px',
      overflow: 'hidden',
      padding: 3,
      paddingLeft: 6,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }),
    menu: () => ({
      position: 'absolute',
      top: '100%',
      width: '100%',
      backgroundColor: 'var(--white)',
      borderRadius: 'var(--inputRadius)',
      border: 0,
      marginTop: '4px',
      zIndex: 1
    }),
    singleValue: () => ({
      color: 'var(--inputColor)',
      fontSize: select2 ? '11px' : 'var(--inputFontSize)'
    }),
    placeholder: () => ({
      margin: 0,
      color: 'var(--inputPlaceholderColor)',
      fontSize: select2 ? '11px' : 'var(--inputFontSize)'
    })
  };

  return (
    <>
      <div className={styles.bx}>
        <RenderIf isTrue={label}>
          <label htmlFor={id} className={select2 ? `label2` : styles.label}>
            {label} {required && <span>*</span>}
          </label>
        </RenderIf>
        <RenderIf isTrue={hookForm === false && typeArray === false}>
          <Select
            ref={ref}
            isMulti={isMulti}
            name={name}
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            error={error}
            defaultMenuIsOpen={defaultMenuIsOpen}
            onInputChange={onInputChange}
            inputValue={inputValue}
          />
        </RenderIf>
        <RenderIf isTrue={hookForm}>
          <Select
            {...field}
            isMulti={isMulti}
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            placeholder={placeholder}
            isLoading={isLoading}
            value={value}
          />
        </RenderIf>
        <RenderIf isTrue={typeArray}>
          <CreatableSelect
            {...field}
            isMulti={multiValue}
            options={options}
            isClearable={true}
            autoFocus
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            placeholder={placeholder}
          />
        </RenderIf>
      </div>
      <div>{error && <div className={styles.error}>{error}</div>}</div>
    </>
  );
};
