import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useFetchProductHook } from 'hooks/products/useFetchProductHook';
import { client } from 'utilities/db';
import { ModalComponent } from 'components/modal';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { getBranchId, getLocationId } from 'utilities/helpers/auth';
import {
  useFetchRecipeProductCategories,
  useFetchRecipeProductGroups
} from 'hooks/recipes/useRecipesHook';
import { CustomSelectDropdown } from 'components/select/multi';
import { useFetchProductsHook } from 'hooks/products/useFetchProductsHook';
import { useStore } from 'store';

export const ProductItselfPage = ({
  action,
  setAction,
  setAdditionType,
  selectedProduct,
  setSelectedProduct
}) => {
  const { productData, productLoading, productRefetch } = useFetchProductHook(
    `product/${selectedProduct}`,
    selectedProduct
  );

  const { brand } = useStore((state) => state);

  const branchId = getBranchId();
  const locationId = getLocationId();

  const { data: groupsData } = useFetchRecipeProductGroups(branchId);
  const { data: categoriesData } = useFetchRecipeProductCategories(branchId);

  const { productsData, productsRefetch } = useFetchProductsHook(
    `products/${brand?.id}/${branchId}`,
    brand?.id,
    branchId
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control
  } = useForm({
    defaultValues: {
      product_name: action === 'update' ? productData?.product_name : '',
      unit: action === 'update' ? productData?.unit : '',
      current_unit_price:
        action === 'update' ? productData?.current_unit_price : '',
      upc: action === 'update' ? productData?.upc : ''
    }
  });

  useMemo(() => {
    if (action) {
      reset({
        product_name: action === 'update' ? productData?.product_name : '',
        unit: action === 'update' ? productData?.unit : '',
        current_unit_price:
          action === 'update' ? productData?.current_unit_price : '',
        upc: action === 'update' ? productData?.upc : ''
      });
    } else {
      reset({
        product_name: action === 'update' ? productData?.product_name : '',
        unit: action === 'update' ? productData?.unit : '',
        current_unit_price:
          action === 'update' ? productData?.current_unit_price : '',
        upc: action === 'update' ? productData?.upc : ''
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, productData]);

  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (groupData) => {
      return client.post('/product/add', groupData);
    }
  );

  const handleSubmitData = (data) => {
    const {
      product_name,
      unit,
      current_unit_price,
      quantity,
      product_group,
      product_category,
      current_quantity,
      upc
    } = data;
    console.log(data, 'product_name');
    mutate({
      product_name: product_name?.value,
      unit: unit?.value,
      current_unit_price: 0,
      product_group_id: product_group?.value,
      product_category_id: product_category?.value,
      brand_id: brand?.id,
      branch_id: branchId,
      quantity: 0,
      upc: upc,
      raw_information: {}
    });
  };

  const closeModal = () => {
    setAction('');
    setAdditionType('');
    setSelectedProduct(null);
  };

  useMemo(() => {
    if (isSuccess) {
      productsRefetch();
      productRefetch();
      Toasts.success('Product added successfully');
      closeModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(
      error?.response?.data?.message?.[0]?.msg || error?.response?.data?.message
    );
  }, [isError]);

  return (
    <>
      <ModalComponent
        modal={closeModal}
        handleModal={closeModal}
        modalMiddle={false}
        rightModal={true}
      >
        <h4>
          <span className="ff_semi">
            {action === 'update' ? 'Update' : 'Add New'} Product
          </span>
        </h4>
        <div className="mt_40"></div>
        <div className="pt_10">
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <Controller
              name="product_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomSelectDropdown
                  hookForm={false}
                  typeArray={true}
                  isMulti={false}
                  field={field}
                  label="Product Name"
                  placeholder="Type or choose product name from options"
                  options={
                    productsData?.map((item) => ({
                      value: item?.product_name,
                      label: item?.product_name
                    })) || []
                  }
                  error={
                    errors?.product_name?.type === 'required'
                      ? 'Product Name is required'
                      : ''
                  }
                  required={true}
                  multiValue={false}
                />
              )}
            />

            {/* <TextField
              label="Product Name"
              placeholder="Product Name"
              name="product_name"
              error={
                errors?.product_name?.type === 'required'
                  ? 'Product Name is required'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
              required={true}
            /> */}
            <div className="mt_16"></div>
            <Controller
              name="unit"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomSelectDropdown
                  hookForm={true}
                  field={field}
                  label="Unit"
                  placeholder="Choose options"
                  options={[
                    'kg',
                    'pounds',
                    'grams',
                    'ounces',
                    'liters',
                    'teaspoon',
                    'number/pieces',
                    'tablespoon',
                    'dozen'
                  ]?.map((unit) => ({
                    value: unit,
                    label: unit?.toUpperCase() || '-'
                  }))}
                  error={
                    errors?.unit?.type === 'required' ? 'Unit is required' : ''
                  }
                  required={true}
                />
              )}
            />
            <div className="mt_16"></div>
            <TextField
              label="Product UPC"
              placeholder="Product upc"
              name="upc"
              error={
                errors?.upc?.type === 'required'
                  ? 'Product UPC is required'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
              required={true}
            />
            {/* <TextField
              label="Unit"
              placeholder="Unit"
              name="unit"
              error={
                errors?.unit?.type === 'required' ? 'Unit is required' : ''
              }
              register={register}
              validation={{
                required: true
              }}
            /> */}
            {/* <TextField
              label="Current Unit Price"
              placeholder="Current Unit Price"
              name="current_unit_price"
              error={
                errors?.current_unit_price?.type === 'required'
                  ? 'Current Unit Price is required'
                  : errors?.current_unit_price?.type === 'pattern'
                  ? 'Current Unit Price is invalid, should be like 10 or 10.00'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
              type="number"
              step="0.01"
              required={true}
            />
            <TextField
              label="Current Quantity"
              placeholder="Current Quantity"
              name="current_quantity"
              error={
                errors?.current_quantity?.type === 'required'
                  ? 'Current Quantity is required'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
              type="number"
              required={true}
            /> */}
            <div className="mt_16"></div>
            <Controller
              name="product_group"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomSelectDropdown
                  hookForm={true}
                  isMulti={false}
                  field={field}
                  label="Product Group"
                  placeholder="Choose Product Group"
                  options={groupsData?.map((item) => ({
                    value: item?.id,
                    label: item?.group_name
                  }))}
                  error={
                    errors?.product_group?.type === 'required'
                      ? 'product Group is required'
                      : ''
                  }
                  required={true}
                />
              )}
            />
            <div className="mt_16"></div>
            <Controller
              name="product_category"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomSelectDropdown
                  hookForm={true}
                  isMulti={false}
                  field={field}
                  label="Product Categories"
                  placeholder="Choose Product Category"
                  options={categoriesData?.map((item) => ({
                    value: item?.id,
                    label: item?.category_name
                  }))}
                  error={
                    errors?.product_category?.type === 'required'
                      ? 'product Category is required'
                      : ''
                  }
                  required={true}
                />
              )}
            />
            <div className="mt_30"></div>
            <hr className="hr_modal_right" />
            <div className="flex item_hend gap_16 mt_30">
              <Button
                onClick={closeModal}
                color="outline-lite"
                label={'Cancel'}
              />
              <Button
                label={action === 'update' ? 'Update' : 'Submit'}
                color="bg-primary"
                type="submit"
                disabled={
                  isLoading || (action === 'update' ? productLoading : false)
                }
              />
            </div>
          </form>
        </div>
      </ModalComponent>
    </>
  );
};
