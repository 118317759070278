import { useState } from 'react';
import { Table } from 'rsuite';
import { RenderIf } from 'utilities/helpers';
import { ProductCategoryPage } from './category';
import { CustomTable } from 'components/datatable';
import { ProductGroupPage } from './group';
import { ProductItselfPage } from './productitself';
import { useFetchProductsHook } from 'hooks/products/useFetchProductsHook';
import { TextField } from 'components/input';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import {
  useFetchRecipeProductCategories,
  useFetchRecipeProductGroups
} from 'hooks/recipes/useRecipesHook';
import { getBranchId } from 'utilities/helpers/auth';
import { useStore } from 'store';

const { Column, HeaderCell, Cell } = Table;

const ProductCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    <b>{rowData?.product_name}</b>
  </Cell>
);

// const ActionsCell = ({
//   setSelectedProduct,
//   setAction,
//   setAdditionType,
//   rowData,
//   dataKey,
//   ...props
// }) => (
//   <Cell {...props}>
//     <button
//       className="button_sm button_radius"
//       color="bg-primary"
//       onClick={() => {
//         setSelectedProduct(rowData?.id);
//         setAction('update');
//         setAdditionType('product');
//       }}
//     >
//       Update
//     </button>
//   </Cell>
// );

export const ProductsPage = () => {
  const [action, setAction] = useState('');
  const [additionType, setAdditionType] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { brand } = useStore((state) => state);

  const branchId = getBranchId();

  const { productsData, productsLoading } = useFetchProductsHook(
    `products/${brand?.id}/${branchId}`,
    brand?.id,
    branchId
  );
  const {
    data: groupsData,
    isLoading: groupLoading,
    refetch: refetchGroups
  } = useFetchRecipeProductGroups(branchId);
  const {
    data: categoriesData,
    isLoading: categoryLoading,
    refetch: refetchCategories
  } = useFetchRecipeProductCategories(branchId);

  const [searchField, setSearchField] = useState('');

  const filteredProductData = productsData?.filter((product) => {
    return (
      product?.product_name
        ?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      product?.unit?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
      product?.upc?.toLowerCase()?.includes(searchField?.toLowerCase())
    );
  });

  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Products</strong>
          </h1>
          {/* <h5 className="mt_4">We hope you're having a good day.</h5> */}
        </div>
      </div>
      <div className={styles.pt_32}></div>
      <div className="add_new_grid">
        <AddCards
          name="fileChecked"
          heading="Add Category"
          description="Easily expand your product catalog by creating new categories."
          onClick={() => setAdditionType('category')}
        />
        <AddCards
          name="fileScratch"
          heading="Add Group"
          description="Group related products together to enhance organization and simplify management."
          onClick={() => setAdditionType('group')}
        />
        <AddCards
          name="fileScratch"
          heading={
            !groupLoading &&
            !categoryLoading &&
            (groupsData?.length === 0 || categoriesData?.length === 0)
              ? 'Add Product (Disabled)'
              : 'Add/Update Product Inventory'
          }
          description={
            categoriesData?.length === 0 && groupsData?.length === 0
              ? 'Please add atleast one product category and one product group'
              : categoriesData?.length === 0
              ? 'Please add atleast one product category'
              : groupsData?.length === 0 
              ? 'Please add atleast one product group'
              : 'Expand your inventory by adding a new product.'
          }
          onClick={() => setAdditionType('product')}
          disabled={
            !groupLoading &&
            !categoryLoading &&
            (groupsData?.length === 0 || categoriesData?.length === 0)
          }
        />
      </div>
      <div>
        <RenderIf isTrue={additionType === 'category'}>
          <ProductCategoryPage
            action={action}
            setAction={setAction}
            setAdditionType={setAdditionType}
            refetchGroups={refetchGroups}
            refetchCategories={refetchCategories}
          />
        </RenderIf>
        <RenderIf isTrue={additionType === 'group'}>
          <ProductGroupPage
            action={action}
            setAction={setAction}
            setAdditionType={setAdditionType}
            refetchGroups={refetchGroups}
            refetchCategories={refetchCategories}
          />
        </RenderIf>
        <RenderIf isTrue={additionType === 'product'}>
          <ProductItselfPage
            action={action}
            setAction={setAction}
            setAdditionType={setAdditionType}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </RenderIf>
      </div>
      <div>
        <div style={{ marginTop: 40 }}></div>
        <h4>
          <span className="ff_semi">Products</span>
        </h4>
        <div style={{ marginTop: 24 }}></div>
        <div className="box2 flex item_vcenter item_space_btn">
          <div className="w_356">
            <TextField
              onChange={(e) => setSearchField(e?.target?.value)}
              value={searchField}
              placeholder={'Type and see result'}
            />
          </div>
          <div></div>
        </div>
        <div style={{ marginTop: 24 }}></div>
        {/* <div className={styles.search_grid}>
          <div>
            <input
              className={`text_field`}
              type={`text`}
              name={`search`}
              placeholder={`Type and see result`}
              onChange={handleChange}
            />
          </div>
          <div></div>
        </div> */}
        <RenderIf isTrue={filteredProductData?.length === 0}>
          <div className={`no_data1`}>No data found.</div>
        </RenderIf>
        <RenderIf isTrue={filteredProductData?.length > 0}>
          <CustomTable
            rows={filteredProductData?.map((product, index) => {
              return {
                id: index + 1,
                product_name: product?.product_name || '-',
                unit: product?.unit || '-',
                upc: product?.upc || '-',
                current_unit_price: product?.current_unit_price || '-',
                added_at: product?.created_at?.split('T')?.[0] || '-'
              };
            })}
            loading={productsLoading}
            pageLimit={10}
            pagination={filteredProductData?.length > 10 ? true : false}
          >
            <Column width={50} align="center" fixed>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column flexGrow={1} sortable>
              <HeaderCell>Product Name</HeaderCell>
              <ProductCell dataKey="product_name" />
            </Column>
            <Column width={220} sortable>
              <HeaderCell>Unit</HeaderCell>
              <Cell dataKey="unit" />
            </Column>
            <Column width={200} sortable>
              <HeaderCell>Upc</HeaderCell>
              <Cell dataKey="upc" />
            </Column>
            {/* <Column width={220} sortable>
              <HeaderCell>Current Unit Price</HeaderCell>
              <Cell dataKey="current_unit_price" />
            </Column> */}
            <Column width={200} sortable>
              <HeaderCell>Added At</HeaderCell>
              <Cell dataKey="added_at" />
            </Column>
            {/* <Column width={120} align="center" fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <ActionsCell
              setSelectedProduct={setSelectedProduct}
              setAction={setAction}
              setAdditionType={setAdditionType}
              dataKey="actions"
            />
          </Column> */}
          </CustomTable>
        </RenderIf>
        {/* <DataTableComponent
          columns={[
            {
              title: 'id',
              dataKey: 'id'
            },
            {
              title: 'Product Name',
              dataKey: 'product_name',
              sortable: true
            },
            {
              title: 'Unit',
              dataKey: 'unit',
              sortable: true
            },
            {
              title: 'Upc',
              dataKey: 'upc',
              sortable: true
            },
            {
              title: 'Current Unit Price',
              dataKey: 'current_unit_price',
              sortable: true
            },
            {
              title: 'Average Unit Price',
              dataKey: 'average_unit_price',
              sortable: true
            }
            {
              title: 'Actions',
              dataKey: 'actions',
              actionHandler: (row) => {
                setSelectedProduct(row?.id);
                setAction('update');
                setAdditionType('product');
              }
            }
          ]}
          rows={productsData?.map((product, index) => {
            return {
              id: index + 1,
              product_name: product?.product_name || '-',
              unit: product?.unit || '-',
              upc: product?.upc || '-',
              current_unit_price: product?.current_unit_price || '-',
              average_unit_price: product?.average_unit_price || '-'
            };
          })}
          loading={productsLoading}
          pageLimit={10}
        /> */}
      </div>
      <div className="mt_40"></div>
    </>
  );
};

const AddCards = ({
  name,
  heading,
  description,
  onClick,
  disabled = false
}) => {
  return (
    <>
      <div>
        <button
          className="add_new_button"
          onClick={() => {
            if (disabled) return;
            onClick();
          }}
          style={{
            background: disabled ? '#e5e5e5' : '#fff',
            cursor: disabled ? 'not-allowed' : 'pointer',
            color: disabled ? '#000' : '#fff'
          }}
        >
          <div>
            <div>
              <Ikon name={name} />
            </div>
            <h4>{heading}</h4>
            <p>{description}</p>
          </div>
        </button>
      </div>
    </>
  );
};
