import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from 'rsuite';
import { RenderIf } from 'utilities/helpers';
import { useFetchInventoryHook } from 'hooks/inventory/useFetchInventoryHook';
import { useFetchProductsHook } from 'hooks/products/useFetchProductsHook';
import { useFetchProductsGroupHook } from 'hooks/products/useFetchProductsGroupHook';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { useFetchProductsCategoryHook } from 'hooks/products/useFetchProductsCategoryHook';
import { useFetchBranches, useFetchBrands } from 'hooks/resourceHooks';
import { CustomTable } from 'components/datatable';
import { PrimaryButton } from 'components/button';
import { TextField } from 'components/input';
import { useStore } from 'store';
import EmptyInventoryImg from './../../assets/Images/Empty_inventory.png';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

const { Column, HeaderCell, Cell } = Table;

const ProductCell = ({ rowData, dataKey, ...props }) => {
  const navigate = useNavigate();
  return (
    <Cell {...props} className={styles.product_name}>
      <button
        type={`button`}
        style={{
          background: `transparent`,
          height: `auto`,
          padding: 0,
          border: 0,
          borderRadius: 0,
          outline: `none`,
          boxShadow: `none`,
          cursor: `pointer`
        }}
        onClick={() => navigate(`/inventory/product/${rowData?.product_id}`)}
      >
        {rowData?.product_name}
      </button>
    </Cell>
  );
};

export const InventoryPage = () => {
  const { brand, user, branch } = useStore((state) => state);
  const navigate = useNavigate();

  const [searchField, setSearchField] = useState('');

  const { inventoryData, inventoryLoading } = useFetchInventoryHook(
    `inventory/${brand?.id}`,
    branch?.id
  );

  const { vendorData, vendorLoading } = useFetchVendorsHook(
    `vendors/${brand?.id}`,
    brand?.id
  );

  const { productsData, productsLoading } = useFetchProductsHook(
    `products/${brand?.id}/${branch?.id}`,
    brand?.id,
    branch?.id
  );
  const { productCategoryData, productCategoryLoading } =
    useFetchProductsCategoryHook('products-categories', branch?.id);
  const { productGroupData, productGroupLoading } = useFetchProductsGroupHook(
    'products-groups',
    branch?.id
  );

  const { data: branchesData, isLoading: branchesLoading } = useFetchBranches(
    brand?.id
  );

  const { data: brandsData, isLoading: brandsLoading } = useFetchBrands(
    user?.id
  );

  const filteredData = inventoryData?.filter((item) => {
    return (
      productsData
        ?.find((product) => product?.id === item?.product_id)
        ?.product_name?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      item?.unit?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
      productCategoryData
        ?.find(
          (productCategory) => productCategory?.id === item?.product_category_id
        )
        ?.category_name?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      productGroupData
        ?.find((productGroup) => productGroup?.id === item?.product_group_id)
        ?.group_name?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      brandsData
        ?.find((brand) => brand?.id === item?.brand_id)
        ?.brand_name?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      branchesData
        ?.find((branch) => branch?.id === item?.branch_id)
        ?.branch_name?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      vendorData
        ?.find((vendor) => vendor?.id === item?.vendor_id)
        ?.vendor_information?.name?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      vendorData
        ?.find((vendor) => vendor?.id === item?.vendor_id)
        ?.vendor_information?.email?.toLowerCase()
        ?.includes(searchField?.toLowerCase())
    );
  });

  return (
    <>
      <header className={styles.header}>
        <div>
          <h2>Inventory</h2>
          {/* <h6>We hope you're having a good day.</h6> */}
        </div>
        <div>
          <PrimaryButton
            onClick={() => {
              navigate(`/inventory/add`);
            }}
          >
            <Ikon name={`plus`} fillColor={`var(--white)`} />
            Add inventory
          </PrimaryButton>
        </div>
      </header>
      <div className={styles.content}>
        <RenderIf isTrue={filteredData?.length === 0}>
          <div className={styles.empty_state}>
            <h2>You currently have no items added to your inventory</h2>
            <picture>
              <source srcSet={EmptyInventoryImg} />
              <img src={EmptyInventoryImg} alt={`Empty Inventory`} />
            </picture>
            <PrimaryButton
              onClick={() => {
                navigate(`/inventory/add`);
              }}
            >
              <Ikon name={`plus`} fillColor={`var(--white)`} />
              Add inventory
            </PrimaryButton>
          </div>
        </RenderIf>
        <RenderIf isTrue={filteredData?.length > 0}>
          <div className={styles.rowEqCol}>
            <div>
              <button
                className={`${styles.statBx} ${styles.activeStat}`}
                onClick={() => {}}
              >
                <div>
                  <div className={styles.statIcon}>
                    <Ikon name={`all-products`} fillColor={`var(--white)`} />
                  </div>
                </div>
                <div>
                  <div className={styles.statLabel}>All Items(s) in Stock</div>
                  <div className={styles.statValue}>{filteredData?.length}</div>
                </div>
              </button>
            </div>
            <div>
              <button className={styles.statBx} onClick={() => {}}>
                <div>
                  <div className={styles.statIcon}>
                    <Ikon name={`users`} />
                  </div>
                </div>
                <div>
                  <div className={styles.statLabel}>
                    All Item(s) in Low Stock
                  </div>
                  <div className={styles.statValue}>
                    {
                      filteredData?.filter((item) => item?.low_stock === true)
                        ?.length
                    }
                  </div>
                  <p></p>
                </div>
              </button>
            </div>
          </div>
          <div style={{ marginTop: 24 }}></div>
          <div className={styles.contentBx}>
            <div className={styles.contentHeader}>
              <h6>
                <span className="ff_semi">Inventory Items</span>
              </h6>
              <div>
                <div className={styles.searchBx}>
                  <button type={`button`} className={styles.searchButton}>
                    <Ikon name={`search`} />
                  </button>
                  <TextField
                    onChange={(e) => setSearchField(e?.target?.value)}
                    value={searchField}
                    placeholder={'Search'}
                  />
                </div>
              </div>
            </div>
            <CustomTable
              rows={filteredData?.map((inv, index) => {
                return {
                  id: index + 1,
                  product_name:
                    productsData?.find(
                      (product) => product?.id === inv?.product_id
                    )?.product_name || '-',
                  product_category:
                    productCategoryData?.find(
                      (productCategory) =>
                        productCategory?.id === inv?.product_category_id
                    )?.category_name || '-',
                  product_group:
                    productGroupData?.find(
                      (productGroup) =>
                        productGroup?.id === inv?.product_group_id
                    )?.group_name || '-',
                  unit_price:
                    '$' + parseFloat(inv?.unit_price)?.toFixed(4) || '-',
                  unit: inv?.converted_unit || '-',
                  quantity: inv?.converted_quantity || '-',
                  total_price:
                    '$' +
                      parseFloat(
                        parseFloat(inv?.unit_price)?.toFixed(4) *
                          inv?.converted_quantity
                      ).toFixed(2) || '-',
                  low_stock: inv?.low_stock ? 'Low Stock' : 'No problem here',
                  product_id: inv?.product_id
                  // brand_name:
                  //   brandsData?.find((brand) => brand?.id === inv?.brand_id)
                  //     ?.brand_name || '-',
                  // branch_name:
                  //   branchesData?.find(
                  //     (branch) => branch?.id === inv?.branch_id
                  //   )?.branch_name || '-',
                  // vendor_name:
                  //   vendorData?.find((vendor) => vendor?.id === inv?.vendor_id)
                  //     ?.vendor_information?.name ||
                  //   vendorData?.find((vendor) => vendor?.id === inv?.vendor_id)
                  //     ?.vendor_information?.email,
                  // purchase_date: inv?.purchase_date || '-',
                  // expiry_date: inv?.expiry_date || '-',
                };
              })}
              loading={
                vendorLoading ||
                productsLoading ||
                inventoryLoading ||
                branchesLoading ||
                brandsLoading ||
                productCategoryLoading ||
                productGroupLoading
              }
              rowHeight={45}
              pageLimit={10}
              pagination={filteredData?.length > 10 ? true : false}
            >
              <Column width={50} fixed>
                <HeaderCell>Id</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              {/* <Link onClick={(e) => navigate(`/inventory/product/${3}`)}> */}
              <Column flexGrow={1} sortable>
                <HeaderCell>Product Name</HeaderCell>
                <ProductCell dataKey="product_name" />
              </Column>
              {/* </Link> */}
              {/* <Column width={100} sortable>
                <HeaderCell>Category</HeaderCell>
                <Cell dataKey="product_category" />
              </Column>
              <Column width={120} sortable>
                <HeaderCell>Group</HeaderCell>
                <Cell dataKey="product_group" />
              </Column> */}
              <Column width={120} sortable>
                <HeaderCell>Unit Price</HeaderCell>
                <Cell dataKey="unit_price" />
              </Column>
              <Column width={100} sortable>
                <HeaderCell>Unit</HeaderCell>
                <Cell dataKey="unit" />
              </Column>
              <Column width={100} sortable>
                <HeaderCell>In Stock</HeaderCell>
                <Cell dataKey="quantity" />
              </Column>
              <Column width={160} sortable>
                <HeaderCell>Stock Alert</HeaderCell>
                <Cell dataKey="low_stock" />
              </Column>
              <Column width={110} sortable>
                <HeaderCell>Total Price</HeaderCell>
                <Cell dataKey="total_price" />
              </Column>

              {/* <Column width={100} sortable>
                <HeaderCell>Brand</HeaderCell>
                <Cell dataKey="brand_name" />
              </Column>
              <Column width={100} sortable>
                <HeaderCell>Branch</HeaderCell>
                <Cell dataKey="branch_name" />
              </Column>
              <Column flexGrow={1} sortable>
                <HeaderCell>Vendor</HeaderCell>
                <Cell dataKey="vendor_name" />
              </Column> */}
              {/* <Column width={140} sortable fixed="right">
                <HeaderCell>Purchase Date</HeaderCell>
                <Cell dataKey="purchase_date" />
              </Column> */}
              {/* <Column width={140} sortable fixed="right">
                <HeaderCell>Expiry Date</HeaderCell>
                <Cell dataKey="expiry_date" />
              </Column> */}
            </CustomTable>
          </div>
        </RenderIf>
      </div>
    </>
  );
};
