import Iframe from 'react-iframe';
import { useStore } from 'store';

export default function CreatePaymentForm() {
  const { brand, branch } = useStore((state) => state);

  return (
    <div style={{ width: '100%', height: 'calc(100vh - 69px)' }}>
      <Iframe
        url={`http://localhost:5173/?buisnessId=${brand?.business_id}&brandId=${brand?.id}&branchId=${branch?.id}&level=branch&action=Onboard_Merchant`}
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        display="block"
        position="relative"
        // overflow="hidden"
        frameBorder={0}
      />
    </div>
  );
}
