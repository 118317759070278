import { useForm, Controller } from 'react-hook-form';
import { days } from 'hooks/resourceHooks';
import { RenderIf } from 'utilities/helpers';
import { ModalComponent } from 'components/modal';
import { CustomSelectDropdown } from 'components/select/multi';
import { TextField } from 'components/input';
import { TextArea } from 'components/textarea';
import ImageDropzone from 'components/imageDropzone/imageDropzone';
import {
  CheckboxField,
  BadgeDayCheckbox,
  StatusRadioField
} from 'components/checkbox';
import { Button } from 'components/button';
import { Toggle } from 'rsuite';
import { useStore } from 'store';

export const AddMenuItem = ({
  allCategories,
  isLoading,
  addMenu,
  onClose,
  uploadImage,
  setUploadImage,
  setUploadImageLoading,
  additionType,
  branchData,
  branchLoading,
  isOpenModal,
  existingMenu = null,
  action = 'add'
}) => {
  const { branch } = useStore((state) => state);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      menu_item_name: action === 'update' ? existingMenu?.menu_item_name : '',
      menu_item_description:
        action === 'update' ? existingMenu?.menu_item_description : '',
      base_price: action === 'update' ? +existingMenu?.price : '',
      taxable:
        action === 'update' && existingMenu?.taxable === 1 ? true : false,
      quantity_threshold:
        action === 'update' ? existingMenu?.quantity_threshold : '',
      force_quantity:
        action === 'update'
          ? existingMenu?.quantity_options?.force_quantity
          : '',
      lock_quantity:
        action === 'update'
          ? existingMenu?.quantity_options?.lock_quantity
          : '',
      hide_quantity:
        action === 'update'
          ? existingMenu?.quantity_options?.hide_quantity
          : '',
      tags:
        action === 'update'
          ? existingMenu?.tags?.map((tag) => ({
              label: tag,
              value: tag
            }))
          : '',
      menu_status:
        action === 'update' && existingMenu?.menu_status ? true : false,
      category:
        action === 'update'
          ? {
              label: existingMenu?.category?.category_name,
              value: existingMenu?.category?.category_name
            }
          : '',
      category_types:
        action === 'update'
          ? {
              label: existingMenu?.categoryTypes?.[0]?.category_type_name,
              value: existingMenu?.categoryTypes?.[0]?.category_type_name
            }
          : {},
      branch_ids: [],
      monday:
        action === 'update' ? existingMenu?.availability_days?.monday : false,
      tuesday:
        action === 'update' ? existingMenu?.availability_days?.tuesday : false,
      wednesday:
        action === 'update'
          ? existingMenu?.availability_days?.wednesday
          : false,
      thursday:
        action === 'update' ? existingMenu?.availability_days?.thursday : false,
      friday:
        action === 'update' ? existingMenu?.availability_days?.friday : false,
      saturday:
        action === 'update' ? existingMenu?.availability_days?.saturday : false,
      sunday:
        action === 'update' ? existingMenu?.availability_days?.sunday : false,
      start_time:
        action === 'update' ? existingMenu?.availability_time?.start_time : '',
      end_time:
        action === 'update' ? existingMenu?.availability_time?.end_time : '',
      pos: action === 'update' ? existingMenu?.sale_channels?.pos : false,
      online_store:
        action === 'update' ? existingMenu?.sale_channels?.online_store : false,
      others: action === 'update' ? existingMenu?.sale_channels?.others : false
    }
  });

  const selectedCategory = watch()?.category?.label
    ? allCategories?.filter(
        (cat) => cat?.category_name === watch()?.category?.label
      )
    : [];

  return (
    <>
      <ModalComponent
        modal={isOpenModal}
        handleModal={onClose}
        modalMiddle={false}
        rightModal={true}
      >
        <RenderIf isTrue={additionType === 'upload'}>
          <>
            <h4>
              <span className="ff_semi">Upload template file</span>
            </h4>
            <div className="mt_40"></div>
            <div className="pt_10">
              <label className="label">Upload file *</label>
              <div className="mt_4"></div>
              <ImageDropzone
                customComponent={
                  <div className="flex item_hcenter text_center">
                    <div>
                      <h4 color="greylite">Drag &amp; drop to upload file</h4>
                      <h4 color="greylite" className="mt_16">
                        OR
                      </h4>
                      <Button
                        type="button"
                        className="button_browse mt_16"
                        label={'Browse file'}
                      />
                    </div>
                  </div>
                }
                setIsLoading={setUploadImageLoading}
                image={uploadImage}
                setImage={setUploadImage}
                large={true}
              />
            </div>
            <div className="mt_30"></div>
            <div className="flex item_vcenter">
              <Button color="bg-titary" label={'Continue'} />
              <Button
                color="outline-lite"
                label={'Download guidebook template'}
              />
            </div>
          </>
        </RenderIf>
        <RenderIf isTrue={additionType !== 'upload'}>
          <>
            <h4>
              <span className="ff_semi">
                <RenderIf isTrue={action === 'add'}>Add new menu item</RenderIf>
                <RenderIf isTrue={action === 'update'}>
                  Update menu item
                </RenderIf>
              </span>
            </h4>
            <div className="mt_40"></div>
            <div className="pt_10"></div>
            <form onSubmit={handleSubmit(addMenu)}>
              <label className="label">Upload file *</label>
              <div className="mt_4"></div>
              <ImageDropzone
                customComponent={
                  <div className="flex item_hcenter text_center">
                    <div>
                      <h4 color="greylite">Drag &amp; drop to upload file</h4>
                      <h4 color="greylite" className="mt_16">
                        OR
                      </h4>
                      <Button
                        type="button"
                        className="button_browse mt_16"
                        label={'Browse file'}
                      />
                    </div>
                  </div>
                }
                setIsLoading={setUploadImageLoading}
                image={uploadImage ? uploadImage : existingMenu?.image}
                setImage={setUploadImage}
              />
              <div className="mt_16"></div>
              <TextField
                name="menu_item_name"
                label={'Item name'}
                type="text"
                error={
                  errors?.menu_item_name?.type === 'required'
                    ? 'Menu item name is required'
                    : ''
                }
                validation={{
                  required: true
                }}
                register={register}
                required
              />
              <div className="mt_16"></div>
              <TextArea
                name="menu_item_description"
                label={'Description'}
                rows="7"
                register={register}
                error={''}
                validation={{}}
              />
              <div className="mt_16"></div>
              <TextField
                name="base_price"
                label={'Item price'}
                placeholder="$ 0.0"
                type="text"
                error={
                  errors?.base_price?.type === 'required'
                    ? 'Item price is required'
                    : ''
                }
                validation={{
                  required: true
                }}
                register={register}
                required
              />
              <div className="mt_16"></div>
              <CheckboxField
                name="taxable"
                id="taxable"
                // value="YES"
                // onChange={() => setIstaxable(!istaxable)}
                register={register}
              >
                This item is taxable
              </CheckboxField>
              <div className="mt_32"></div>
              <TextField
                name="quantity_threshold"
                label={'Default quantity (optional)'}
                placeholder="1"
                type="number"
                register={register}
              />
              <div className="mt_32"></div>
              <div className="flex">
                <CheckboxField
                  name="force_quantity"
                  id="force_quantity"
                  register={register}
                >
                  Force quantity
                </CheckboxField>
                <CheckboxField
                  name="lock_quantity"
                  id="lock_quantity"
                  register={register}
                >
                  Lock quantity
                </CheckboxField>
                <CheckboxField
                  name="hide_quantity"
                  id="hide_quantity"
                  register={register}
                >
                  Hide quantity
                </CheckboxField>
              </div>
              <div className="mt_32"></div>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <CustomSelectDropdown
                    hookForm={false}
                    typeArray={true}
                    isMulti={true}
                    field={field}
                    label="Item tags (optional)"
                    placeholder="Enter tag name and press enter key"
                  />
                )}
              />
              <div className="mt_32"></div>
              <hr className="hr_modal_right" />
              <div className="mt_32"></div>
              <div>
                <h5>
                  <strong>Item settings</strong>
                </h5>
                <div className="mt_25">
                  <label className="label">Item status</label>
                  <div className="mt_4"></div>
                  <Controller
                    name="menu_status"
                    control={control}
                    render={({ field }) => (
                      <Toggle
                        checkedChildren="ACTIVE"
                        unCheckedChildren="INACTIVE"
                        {...field}
                        defaultChecked={field.value}
                      />
                    )}
                  />
                </div>
                <div className="mt_16">
                  <Controller
                    name="category"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomSelectDropdown
                        hookForm={true}
                        field={field}
                        label="Item linked with menu category"
                        placeholder="Choose category"
                        options={allCategories?.map((item) => ({
                          label: item?.category_name,
                          value: item?.category_name
                        }))}
                        value={field.value}
                      />
                    )}
                  />
                </div>
                <RenderIf isTrue={watch()?.category}>
                  <div className="mt_16"></div>
                  <Controller
                    name="category_types"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomSelectDropdown
                        hookForm={true}
                        isMulti={false}
                        field={field}
                        label="Item linked with menu category types"
                        placeholder="Choose category types"
                        options={
                          selectedCategory?.length
                            ? selectedCategory[0]?.category_types?.map(
                                (type) => ({
                                  label: type,
                                  value: type
                                })
                              )
                            : []
                        }
                        value={field.value}
                      />
                    )}
                  />
                </RenderIf>
                <RenderIf isTrue={!watch()?.category}>
                  <div className="mt_16"></div>
                  <Controller
                    name="branch_ids"
                    control={control}
                    render={({ field }) => (
                      <CustomSelectDropdown
                        hookForm={true}
                        isMulti
                        field={field}
                        label="Item linked with branches (optional)"
                        placeholder="Choose branches"
                        options={[
                          {
                            label: branch?.branch_name,
                            value: branch?.id
                          }
                        ]}
                      />
                    )}
                  />
                </RenderIf>
                <div className="mt_16"></div>
                <label className="label">Availability days (optional)</label>
                <div className="mt_4"></div>
                <div className="select_days">
                  {days?.map((day, index) => (
                    <BadgeDayCheckbox
                      key={index}
                      name={day?.value}
                      id={day?.value}
                      label={day?.label}
                      register={register}
                    />
                  ))}
                </div>
                <div className="mt_16"></div>
                <div className="grid grid_gap20">
                  <div className="colxs6">
                    <TextField
                      name="start_time"
                      label={'Availability start time (optional)'}
                      type="text"
                      register={register}
                    />
                  </div>
                  <div className="colxs6">
                    <TextField
                      name="end_time"
                      label={'Availability end time (optional)'}
                      type="text"
                      register={register}
                    />
                  </div>
                </div>
                <div className="mt_32"></div>
                <label className="label">Linked with stores (optional)</label>
                <div className="mt_4"></div>
                {/* <CheckboxField name="pos" id="pos" register={register}>
                  POS
                </CheckboxField> */}
                <div className="mt_10"></div>
                <CheckboxField
                  name="online_store"
                  id="online_store"
                  register={register}
                >
                  Online store
                </CheckboxField>
                {/* <div className="mt_10"></div>
                <CheckboxField name="others" id="others" register={register}>
                  Online ordering through partner websites and apps
                </CheckboxField> */}
              </div>
              <div className="mt_32"></div>
              <hr className="hr_modal_right" />
              <div className="flex item_hend mt_32">
                <Button
                  onClick={onClose}
                  color="outline-lite"
                  label={'Cancel'}
                />
                <Button
                  isLoading={isLoading}
                  color="bg-titary"
                  type="submit"
                  label={action === 'update' ? 'Update' : 'Continue'}
                />
              </div>
            </form>
          </>
        </RenderIf>
      </ModalComponent>
    </>
  );
};
