import React from 'react';
import { Table, Pagination } from 'rsuite';
import { RenderIf } from 'utilities/helpers';

const { Column, HeaderCell, Cell } = Table;

export const DataTableComponent = ({
  columns,
  rows,
  loading,
  pageLimit,
  rowHeight = 40
}) => {
  const [limit, setLimit] = React.useState(pageLimit || 10);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = rows?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  return (
    <div>
      <Table
        //height={400}
        autoHeight={true}
        data={getData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
        headerHeight={43}
        rowHeight={rowHeight}
      >
        {columns?.map((column, index) => {
          return (
            <>
              <Column
                key={index}
                width={
                  index === 0
                    ? 80
                    : index === 2
                    ? 300
                    : index === 5
                    ? 200
                    : index === columns?.length - 1
                    ? 100
                    : 212
                }
                fixed={
                  index === 0
                    ? 'left'
                    : index === columns?.length - 1
                    ? 'right'
                    : null
                }
                sortable={column?.sortable ? true : false}
                align={index === 0 ? `center` : ``}
              >
                <HeaderCell>{column?.title}</HeaderCell>
                <Cell
                  dataKey={column?.dataKey !== 'actions' ? column?.dataKey : ''}
                >
                  {(rowData, rowIndex) => {
                    return (
                      <div key={rowIndex}>
                        {column?.dataKey === 'actions' ? (
                          <button
                            className="button_sm button_radius"
                            color="bg-primary"
                            onClick={() => column?.actionHandler(rowData)}
                          >
                            Update
                            {/* {column?.title || 'Edit'} */}
                          </button>
                        ) : (
                          rowData[column?.dataKey]
                        )}
                      </div>
                    );
                  }}
                </Cell>
              </Column>
            </>
          );
        })}
      </Table>
      <div style={{ padding: '20px 0 0 0' }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={rows?.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  );
};

export const CustomTable = (props) => {
  const {
    rows,
    loading,
    pageLimit,
    children,
    pagination = false,
    expandedRowKeys = [],
    renderRowExpanded = () => {},
    rowKey = 'id',
    headerHeight = 43,
    rowHeight = 40
  } = props;

  const [limit, setLimit] = React.useState(pageLimit || 10);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = rows?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  return (
    <>
      <article>
        <Table
          //height={400}
          autoHeight={true}
          data={getData() || []}
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
          loading={loading}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          expandedRowKeys={expandedRowKeys}
          renderRowExpanded={renderRowExpanded}
          rowKey={rowKey}
          rowExpandedHeight={182}
        >
          {children}
        </Table>
        <RenderIf isTrue={pagination}>
          <div style={{ padding: '16px 16px 14px' }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={['total', '-', 'pager']}
              total={rows?.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </RenderIf>
      </article>
    </>
  );
};
